<template>
  <div :class="appTheme">
    <!-- <Banner /> -->
    <div class="dark:text-white light-text-color">
      <header
        x-data="{ mobileMenuOpen : false }"
        class="
          flex flex-wrap flex-row
          justify-between
          md:items-center md:space-x-4
          py-6
          px-6
          top-0
          z-50
          animated
          shadow-lg
          dark:bg-secondary-dark
          bg-white
          relative
          hidden
        "
        ref="nav"
      >
        <router-link class="block" :to="{ path: '/' }">
          <span class="sr-only"></span>
          <!-- <img
            class=""
            src="./assets/images/muf.png"
            alt="Logo"
            width="50"
            height="50"
            title="Logo"
          /> -->
        </router-link>
        <span class="inline-block md:hidden"
          ><ThemeChanger :theme="appTheme"
        /></span>

        <button
          @click="mobileMenuOpen = !mobileMenuOpen"
          class="inline-block md:hidden w-8 h-8 p-1"
        >
          <svg
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>

        <nav
          class="
            absolute
            md:relative
            top-16
            left-0
            md:top-0
            z-20
            md:flex
            flex-col
            md:flex-row md:space-x-6
            font-semibold
            w-full
            md:w-auto
            shadow-md
            rounded-lg
            md:rounded-none md:shadow-none
            p-6
            pt-0
            md:p-0
            dark:bg-secondary-dark
            bg-white
          "
          :class="{ flex: mobileMenuOpen, hidden: !mobileMenuOpen }"
          click="mobileMenuOpen = false"
        >
          <router-link
            :to="{ path: path, hash: '#projects' }"
            class="block py-1"
            @click="mobileMenuOpen = false"
            >Projects</router-link
          >
          <router-link
            :to="{ path: path, hash: '#skills' }"
            class="block py-1"
            @click="mobileMenuOpen = false"
            >SKills</router-link
          >
          <router-link
            :to="{ path: path, hash: '#education' }"
            class="block py-1"
            @click="mobileMenuOpen = false"
            >Education</router-link
          >
          <router-link
            :to="{ path: path, hash: '#certificates' }"
            class="block py-1"
            @click="mobileMenuOpen = false"
            >Certificates</router-link
          >
          <router-link
            to="/quotes"
            class="block py-1"
            @click="mobileMenuOpen = false"
            >Quotes</router-link
          >
          <router-link
            :to="{ path: path, hash: '#contact' }"
            class="block py-1"
            @click="mobileMenuOpen = false"
            >Contact</router-link
          >

          <span class="hidden md:inline-block mt-1"
            ><ThemeChanger :theme="appTheme"
          /></span>
        </nav>
      </header>

      <div class="" ref="main"><router-view /></div>
      <!-- <hr /> -->
      <footer class="text-gray-600 body-font hidden">
        <div class="bg-gray-100 dark:text-white dark:bg-secondary-dark">
          <div
            class="
              container
              px-5
              py-6
              mx-auto
              flex
              items-center
              sm:flex-row
              flex-col
            "
          >
            <router-link
              to="/"
              class="
                flex
                title-font
                font-medium
                items-center
                md:justify-start
                justify-center
              "
            >
              <span class="ml-3">
                <!-- <img
                  src="./assets/images/muf.png"
                  style="width: 80px; height 80px;"
                  alt="Muhammad Umer Farooq"
                /> -->
              </span>
            </router-link>
            <p class="inline-flex text-sm text-gray- 500 sm:ml-6 sm:mt-0 mt-4">
              Made with &nbsp;
              <img class="h-6 w-6" :src="require('./assets/icons/heart.gif')" />
              &nbsp; By &nbsp;
              <a
                class="font-bold"
                href="https://alphasofthub.com"
                target="_blank"
                >AlphaSoftHub (Pvt) Ltd</a
              >
            </p>
          </div>
        </div>
      </footer>
    </div>
    <button
      v-if="top"
      class="backtotop bg-gray-300"
      v-on:click="scrollTop()"
      title="Go to top"
    >
      <i class="text-black fa">
        <img
          style="width: 0.75em; height: 0.75em"
          :src="require('./assets/icons/arrowup.svg')"
        />
      </i>
    </button>
  </div>
</template>

<script lang="js">
//import Banner from "@/components/Banner";
import ThemeChanger from "@/components/ThemeChanger";
import { mapState } from "vuex";

// fancybox.
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

export default {
  name: 'app',
  components: {
    ThemeChanger,
   // Banner
  },
  watch: {
    $route(to, from) {
      const base = `Account`
      let title = ""
      if (to.path !== "/") this.path = '/'
      else this.path = ""

      if (typeof to.meta.title === 'string')
        title = `${to.meta.title} - ${base}`
      else if (typeof to.meta.title === 'function')
        title = `${to.meta.title(to)} - ${base}`
      else title = base
      document.title = title
    },
  },
  data() {
    return {
      top: false,
      nav: {},
      mobileMenuOpen: false,
      hidden: false,
      appTheme: localStorage.getItem('theme') || 'light',
      path: "",
      scroll: 0,
    }
  },
  created() {
    // Fancybox init.
    let fancyboxELems = ["[data-fancybox='default']", "[data-fancybox='certificate']"]
    // for in loop.
    for (let i = 0; i < fancyboxELems.length; i++) {
      Fancybox.bind(fancyboxELems[i], {
        caption: function (fancybox, carousel, slide) {
          return (
            slide.caption || ""
          );
        },
     });
    }

    // back to top button handler.
    window.addEventListener('scroll', this.handleScroll);

    // subscribe to theme changes.
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'Theme/setTheme') {
        this.appTheme = mutation.payload
      }
    })
  },
  computed: {
    theme() {
        return this.$store.getters['Theme/theme']
    },
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    },
    manageNavScroll(t) {
      let nav = this.$refs.nav
      let main = this.$refs.main
      if (t === 'add') {
        nav.classList.add('fixed')
        nav.classList.add('w-full')
        nav.classList.add('top-0')
        nav.classList.remove('relative')
        main.classList.add('mt-20')
        return;
      }
      nav.classList.remove('fixed')
      nav.classList.remove('w-full')
      nav.classList.remove('top-0')
      nav.classList.add('relative')
      main.classList.remove('mt-20')
    },
    handleScroll(event) {
      let scroll = document.body.scrollTop || document.documentElement.scrollTop;
      if (scroll > 150) {
         if (scroll > 300) {
           if (this.scroll < scroll) {
             this.manageNavScroll('remove')
           } else {
             this.manageNavScroll('add')
           }
         }

        this.top = true
      } else {
        this.top = false
        this.manageNavScroll('remove')
      }
      this.scroll = scroll;
    },
  },
  beoreDestroy() {
    this.unsubscribe()
  },
}
</script>
